import React, { useState, useEffect } from 'react'
import { graphql } from "gatsby"
import Util from "@components/core/util"
import Layout from "@components/layout"
import PostCardList from "@templates/post-card-list"
import "@styles/search.scss"
import SEO from "@components/seo"

const seo = {
  title: '검색 결과',
  url: 'https://blog.buildit.kr/search',
  isHome: false,
  isPost: false,
  isMenu: false
}


const getSearchTitleText  = (searchValue) => {
  return '"' + searchValue + '" 에 대한 검색 결과'
}

const Search = ({ location, data }) => {
  const uri = '/search'
  // const href = location.href
  let searchValue = '', currentPage = 1
  let filteredData = []
  let stateFromLocation = location.state
  if (stateFromLocation) {
    searchValue = stateFromLocation.searchValue
    if (stateFromLocation.currentPage) {
      currentPage = stateFromLocation.currentPage
    }
    seo.title = getSearchTitleText(searchValue)
  }

  const allPosts = Util.getAllPostsToSearch(data)
  const [seoTitle, setSeoTitle] = useState(seo.title)
  const [query, setQuery] = useState(String(searchValue))
  const fromSearch = true

  useEffect(() => {
    searchValue = Util.getHashParameterFromUrl('keyword', location)
    setSeoTitle(getSearchTitleText(searchValue))
    setQuery(searchValue)
  }, [])

  useEffect(() => {
    searchValue = Util.getHashParameterFromUrl('keyword', location)
    setSeoTitle(getSearchTitleText(searchValue))
    setQuery(searchValue)
  }, [searchValue]);

  filteredData = allPosts.filter(post => {
    const { title, content, categoryName } = post
    let queryLowered = String(query).toLowerCase()
    if (queryLowered === '') return (false)
    let existedInContent = content.toLowerCase().includes(queryLowered)
    let existedInTitle = title.toLowerCase().includes(queryLowered)
    let existedInCategoryName = categoryName.includes(queryLowered)
    let filterable = (existedInContent || existedInTitle || existedInCategoryName)
    // if (filterable) console.log(existedInContent, existedInTitle, existedInCategoryName)
    return (filterable)
  })
  console.log(filteredData)
    
  return (
    <Layout>
      <SEO 
        title={seoTitle}
        url={seo.url}
        isHome={seo.isHome}
        isPost={seo.isPost}
        linkKey={seo.linkKey}
        isMenu={seo.isMenu} />
      <div className="search-page">
        <div className="search-header common-page-header">
          <span className="title">검색 |</span>
          <span
            className="keyword">{query}</span>
        </div>
        <div className="search-result">
          {
            filteredData.length > 0 &&
            <PostCardList
            uri={uri + '#keyword=' + query}
            searchValue={searchValue}
            fromSearch={fromSearch}
            currentPage={currentPage}
            postList={filteredData}/>
          }
          {
            filteredData.length === 0 &&
            (
              <div className="no-search-results" align="center">
                <p className="guide-text page">입력하신 검색어가 포함된 게시물을 찾을 수 없습니다.</p>
                {/* <p className="guide-text result">입력하신 검색어가 포함된 게시물을 찾을 수 없습니다.</p> */}
                <p className="guide-text try">다른 검색어를 입력해보세요.</p>
              </div>  
            )
          }
        </div>
      </div>
    </Layout>)
}

export default Search

export const postQuery = graphql`
{
  blog {
    allPosts {
      edges {
        node {
          id
          title
          contentNl
          menu {
            id
            name
          }
          category {
            id
            name
          },
          desc
          date
          thumbnailPath
        }
      }
    }
  }
}
`